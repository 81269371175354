//@ts-nocheck
import {
    Box,
    Button,
    CircularProgress,
    Container,
    IconButton,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@material-ui/core"
import React, { useCallback, useMemo, useState } from "react"
import BaseContainer from "../../components/BaseContainer"
import { api, snackbarCatch } from "../../services/api"
import DataTable, { TableColumn } from "react-data-table-component"
import {
    AdminGetAmbassadorStatsSortByEnum,
    AdminGetAmbassadorStatsSortDirectionEnum,
    AdminGetAmbassadorStatsDto,
} from "../../services/openapi"
import { AddRounded, DeleteRounded, ExpandMoreRounded } from "@material-ui/icons"
import { useSnackbar } from "notistack"
import ApproveProfileModal from "./ApproveProfileModal"

const defaultParams: {
    page: number
    limit: number
    sortBy: AdminGetAmbassadorStatsSortByEnum
    sortDirection: AdminGetAmbassadorStatsSortDirectionEnum
} = {
    page: 1,
    limit: 10,
    sortBy: "created_at",
    sortDirection: "asc",
}

const AmbassadorScene = () => {
    const { enqueueSnackbar } = useSnackbar()
    const [page, setPage] = useState(defaultParams.page)
    const [limit, setLimit] = useState(defaultParams.limit)
    const [sorting, setSorting] = useState<{
        sortBy: AdminGetAmbassadorStatsSortByEnum
        sortDirection: AdminGetAmbassadorStatsSortDirectionEnum
    }>({
        sortBy: defaultParams.sortBy,
        sortDirection: defaultParams.sortDirection,
    })
    const [modalData, setModalData] = useState(null as null | string)

    const onSortChanged = useCallback(
        (column: TableColumn<AdminGetAmbassadorStatsDto>, direction: "asc" | "desc") => {
            setSorting({
                sortBy: column.sortField! as AdminGetAmbassadorStatsSortByEnum,
                sortDirection: direction,
            })
        },
        [setSorting],
    )

    const ambassadors = api.ambassador.useAmbassadors({
        page,
        limit,
        ...sorting,
    })
    const { mutateAsync: approveAmbassadorMutation } = api.ambassador.useApproveAmbassador()

    const approveAmbassador = useCallback(
        async (ambassadorId: string) => {
            try {
                await approveAmbassadorMutation({
                    ambassadorId,
                })
                enqueueSnackbar("Ambassadeur approuvé", { variant: "success" })
            } catch (e) {
                snackbarCatch(e, enqueueSnackbar)
            }
        },
        [approveAmbassadorMutation],
    )

    const columns = useMemo<TableColumn<AdminGetAmbassadorStatsDto>[]>(
        () => [
            {
                name: "Ambassadeur",
                selector: (row) => row.ambassador_username || row.username,
                sortField: "ambassador_username",
                sortable: true,
            },
            {
                name: "Nb commentaires",
                selector: (row) => (!row.need_approbation && row.comment_count ? row.comment_count : "-"),
                sortField: "comment_count",
                sortable: true,
            },
            {
                name: "Nb likes",
                selector: (row) => (!row.need_approbation && row.like_count ? row.like_count : "-"),
                sortField: "like_count",
                sortable: true,
            },
            {
                name: "Nb abonnements",
                selector: (row) => (!row.need_approbation && row.follower_count ? row.follower_count : "-"),
                sortField: "follower_count",
                sortable: true,
            },
            {
                name: "Approbation",
                center: true,
                cell: function formatCell(row) {
                    if (row.need_approbation) {
                        return <Button onClick={() => approveAmbassador(row.ambassador_id)}>Approuver</Button>
                    }
                    return <Typography>Approuvé</Typography>
                },
            },
            {
                name: "Approbation profile",
                center: true,
                cell: function formatCell(row) {
                    if (row.has_draft_profile) {
                        return <Button onClick={() => setModalData(row.ambassador_id)}>Regarder le profile</Button>
                    }
                    return <Typography>Profile à jour</Typography>
                },
            },
        ],
        [approveAmbassador],
    )

    if (ambassadors.isLoading || ambassadors.data === undefined) {
        return (
            <BaseContainer>
                <Typography variant="h3" component="h1">
                    Activité ambassadeurs
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                    <CircularProgress />
                </Box>
            </BaseContainer>
        )
    }

    return (
        <BaseContainer>
            <Typography variant="h3" component="h1">
                Activité ambassadeurs
            </Typography>

            <ApproveProfileModal ambassadorId={modalData} onClose={() => setModalData(null)} />

            <Box mt={3} />

            <DataTable
                persistTableHead
                // noDataComponent
                columns={columns}
                data={ambassadors.data.data}
                responsive
                onSort={onSortChanged}
                sortServer
                noContextMenu
                noHeader
                expandOnRowDoubleClicked
                selectableRowsHighlight
                highlightOnHover
                sortIcon={<ExpandMoreRounded />}
                selectableRowsComponentProps={{ color: "primary" }}
                // onRowClicked
                pagination
                paginationServer
                paginationPerPage={ambassadors.data.limit}
                paginationTotalRows={ambassadors.data.totalCount}
                paginationRowsPerPageOptions={[5, 10, 20]}
                paginationDefaultPage={defaultParams.page}
                defaultSortAsc={defaultParams.sortDirection === "asc"}
                defaultSortFieldId={defaultParams.sortBy}
                onChangeRowsPerPage={setLimit}
                onChangePage={setPage}
                // fixedHeader
                // fixedHeaderScrollHeight
                keyField="ambassador_id"
            />
        </BaseContainer>
    )
}
export default AmbassadorScene
