import { ProviderContext } from "notistack";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router";
import { queryClient } from "..";
import {
  AdminApi,
  AdminGetAmbassadorStatsRequest,
  AdminGetUsersRequest,
  AdminGetVouchersRequest,
  AdminMooverStatsRequest,
  UsersApi,
  AdminGetCagnotteRequest,
  AdminGetOwnerExpensesRequest,
  AdminGetAdvertiserBudgetRequest,
  AdminGetNewRestaurantsRequest,
  AdminGetOwnerExchangeOrderRequest,
  AdminGetAdvertiserStatsRequest,
  AdminGetOwnerStatsRequest,
  AdminGetMooverExchangeOrderAttributedRequest,
  RestaurantOwnersApi,
  AdminGetAdmobRequest,
} from "./openapi";

export const key = {
  auth: {
    getUser: "getUser",
  },
  user: {
    getUsers: "getUsers",
  },
  voucher: {
    getVouchers: "getVouchers",
  },
  ambassador: {
    getAmbassadors: "getAmbassadors",
    getDraftProfile: "getDraftProfile",
  },
  moover: {
    getMooverStats: "getMooverStats",
    getMooverExchangeOrder: "getMooverExchangeOrder",
    getMooverExchangeOrderAttributed: "getMooverExchangeOrderAttributed",
  },
  cagnotte: {
    getCagnotte: "getCagnotte",
    getAdmob: "getAdmob",
  },
  advertiser: {
    getAdBudget: "getAdBudget",
    getAdStats: "getAdStats",
  },
  owner: {
    getOwnerExpenses: "getOwnerExpenses",
    getOwnerStats: "getOwnerStats",
    getNewRestaurants: "getNewRestaurants",
    getOwnerExchangeOrder: "getOwnerExchangeOrder",
    getCreationStats: "getCreationStats",
  },
} as const;

export const api = {
  auth: {
    useUser: () => {
      return useQuery({
        queryKey: key.auth.getUser,
        queryFn: UsersApi.getUser,
        useErrorBoundary: false,
        notifyOnChangeProps: ["data", "error"],
        retry: false,
      });
    },
    useLogin: () => {
      return useMutation({
        mutationFn: AdminApi.adminLogin,
        onSuccess: () => {
          queryClient.invalidateQueries(key.auth.getUser);
        },
      });
    },
    useLogout: () => {
      const history = useHistory();
      return useMutation({
        mutationFn: AdminApi.adminLogout,
        onSuccess: () => {
          queryClient.clear();
          history.go(0);
        },
      });
    },
  },
  user: {
    useUsers: (data: AdminGetUsersRequest) => {
      return useQuery({
        queryKey: [key.user.getUsers, data],
        queryFn: () => AdminApi.adminGetUsers(data),
        keepPreviousData: true,
      });
    },
    useUpdateUser: () => {
      return useMutation({
        mutationFn: AdminApi.adminUpdateUser,
        onSuccess: () => {
          queryClient.invalidateQueries(key.user.getUsers);
        },
      });
    },
    useDeleteUser: () => {
      return useMutation({
        mutationFn: AdminApi.adminDeleteUser,
        onSuccess: () => {
          queryClient.invalidateQueries(key.user.getUsers);
        },
      });
    },
    useUpdateUserVisibility: () => {
      return useMutation({
        mutationFn: AdminApi.adminUpdateUserVisibility,
        onSuccess: () => {
          queryClient.invalidateQueries(key.user.getUsers);
        },
      });
    },
  },
  voucher: {
    useVouchers: (data: AdminGetVouchersRequest) => {
      return useQuery({
        queryKey: [key.voucher.getVouchers, data],
        queryFn: () => AdminApi.adminGetVouchers(data),
        keepPreviousData: true,
      });
    },
    useCreateVoucher: () => {
      return useMutation({
        mutationFn: AdminApi.adminCreateVoucher,
        onSuccess: () => {
          queryClient.invalidateQueries(key.voucher.getVouchers);
        },
      });
    },
    useDeleteVoucher: () => {
      return useMutation({
        mutationFn: AdminApi.adminDeleteVoucher,
        onSuccess: () => {
          queryClient.invalidateQueries(key.voucher.getVouchers);
        },
      });
    },
  },
  ambassador: {
    useAmbassadors: (data: AdminGetAmbassadorStatsRequest) => {
      return useQuery({
        queryKey: [key.ambassador.getAmbassadors, data],
        queryFn: () => AdminApi.adminGetAmbassadorStats(data),
        keepPreviousData: true,
      });
    },
    useAmbassadorProfile: (ambassadorId: string | null) => {
      return useQuery({
        queryKey: [key.ambassador.getDraftProfile, ambassadorId],
        queryFn: () =>
          AdminApi.adminGetAmbassadorDraftProfile({
            ambassadorId: ambassadorId!,
          }),
        keepPreviousData: true,
        enabled: ambassadorId !== null,
      });
    },
    useApproveAmbassador: () => {
      return useMutation({
        mutationFn: AdminApi.adminApproveAmbassador,
        onSuccess: () => {
          queryClient.invalidateQueries(key.ambassador.getAmbassadors);
        },
      });
    },
    useApproveAmbassadorDraftProfile: () => {
      return useMutation({
        mutationFn: AdminApi.adminApproveAmbassadorDraftProfile,
        onSuccess: () => {
          queryClient.invalidateQueries(key.ambassador.getAmbassadors);
          queryClient.invalidateQueries(key.ambassador.getDraftProfile);
        },
      });
    },
  },
  moover: {
    useMooverStats: (data: AdminMooverStatsRequest) => {
      return useQuery({
        queryKey: [key.moover.getMooverStats, data],
        queryFn: () => AdminApi.adminMooverStats(data),
        refetchOnWindowFocus: false,
      });
    },
    useMooverExchangeOrder: () => {
      return useQuery({
        queryKey: [key.moover.getMooverExchangeOrder],
        queryFn: () => AdminApi.adminGetMooverExchangeOrder(),
      });
    },
    useMooverExchangeOrderAttributed: (
      data: AdminGetMooverExchangeOrderAttributedRequest
    ) => {
      return useQuery({
        queryKey: [key.moover.getMooverExchangeOrderAttributed, data],
        queryFn: () => AdminApi.adminGetMooverExchangeOrderAttributed(data),
        keepPreviousData: true,
      });
    },
  },
  cagnotte: {
    useCagnotte: (data: AdminGetCagnotteRequest) => {
      return useQuery({
        queryKey: [key.cagnotte.getCagnotte, data],
        queryFn: () => AdminApi.adminGetCagnotte(data),
        keepPreviousData: true,
      });
    },
    useAjoutAdmob: () => {
      return useMutation({
        mutationFn: AdminApi.adminAjoutAdmob,
      });
    },
    GetAdmob: (data: AdminGetAdmobRequest) => {
      return useQuery({
        queryKey: [key.cagnotte.getAdmob, data],
        queryFn: () => AdminApi.adminGetAdmob(data),
        keepPreviousData: true,
      });
    },
  },
  advertiser: {
    useAdvertiserBudget: (data: AdminGetAdvertiserBudgetRequest) => {
      return useQuery({
        queryKey: [key.advertiser.getAdBudget, data],
        queryFn: () => AdminApi.adminGetAdvertiserBudget(data),
        keepPreviousData: true,
      });
    },
    useAdvertiserStats: (data: AdminGetAdvertiserStatsRequest) => {
      return useQuery({
        queryKey: [key.advertiser.getAdStats, data],
        queryFn: () => AdminApi.adminGetAdvertiserStats(data),
      });
    },
  },
  
  owner: {
    useOwnerExpenses: (data: AdminGetOwnerExpensesRequest) => {
      return useQuery({
        queryKey: [key.owner.getOwnerExpenses, data],
        queryFn: () => AdminApi.adminGetOwnerExpenses(data),
        keepPreviousData: true,
      });
    },
    useOwnerStats: (data: AdminGetOwnerStatsRequest) => {
      return useQuery({
        queryKey: [key.owner.getOwnerStats, data],
        queryFn: () => AdminApi.adminGetOwnerStats(data),
      });
    },
    useNewRestaurants: (data: AdminGetNewRestaurantsRequest) => {
      return useQuery({
        queryKey: [key.owner.getNewRestaurants, data],
        queryFn: () => AdminApi.adminGetNewRestaurants(data),
        keepPreviousData: true,
      });
    },
    useOwnerExchangeOrder: (data: AdminGetOwnerExchangeOrderRequest) => {
      return useQuery({
        queryKey: [key.owner.getOwnerExchangeOrder, data],
        queryFn: () => AdminApi.adminGetOwnerExchangeOrder(data),
        keepPreviousData: true,
      });
    },
    useCreationStats: (data: any) => {
      return useQuery({
        queryKey: [key.owner.getCreationStats, data],
        queryFn: () => RestaurantOwnersApi.getCreationStats(data),
        keepPreviousData: true,
      });
    },
    useJobOfferStats: (data: any) => {
      return useQuery({
        queryKey: [key.owner.getCreationStats, data],
        queryFn: () => RestaurantOwnersApi.getJobOfferStats(data),
        keepPreviousData: true,
      });
    },
    useClientStats: (data: any) => {
      return useQuery({
        queryKey: [key.owner.getCreationStats, data],
        queryFn: () => RestaurantOwnersApi.getClientStats(data),
        keepPreviousData: true,
      });
    },
    TotalDiscount: (data: any) => {
      return useQuery({
        queryFn: () => RestaurantOwnersApi.Discount(data),
        keepPreviousData: true,
      });
    },
  },
};

export const snackbarCatch = (
  err: unknown,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"]
) => {
  enqueueSnackbar(
    (typeof err === "object" && (err as { message?: string }).message) ||
      "Internal error",
    {
      variant: "error",
    }
  );
};
