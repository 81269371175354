//@ts-nocheck
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import BaseContainer from "../../components/BaseContainer";
import { api, snackbarCatch } from "../../services/api";
import DataTable, { TableColumn } from "react-data-table-component";
import {
  AdminGetUsersSortByEnum,
  AdminGetUsersSortDirectionEnum,
  AdminGetUserDto,
} from "../../services/openapi";
import { Icon } from "@mui/material";

import {
  AddRounded,
  DeleteRounded,
  ExpandMoreRounded,
  RemoveRedEye,
  VisibilityOff,
} from "@material-ui/icons";
import halfVis from "./halfvisiblez.png";
import { useSnackbar } from "notistack";
import { format, set } from "date-fns";
import { fr } from "date-fns/locale";
import { parseISO } from "date-fns/esm";
const defaultParams: {
  page: number;
  limit: number;
  sortBy: AdminGetUsersSortByEnum;
  sortDirection: AdminGetUsersSortDirectionEnum;
} = {
  page: 1,
  limit: 10,
  sortBy: "created_at",
  sortDirection: "asc",
};

const DashboardScene = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(defaultParams.page);
  const [limit, setLimit] = useState(defaultParams.limit);
  const [accountType, setAccountType] = useState("all");

  const [sorting, setSorting] = useState<{
    sortBy: AdminGetUsersSortByEnum;
    sortDirection: AdminGetUsersSortDirectionEnum;
  }>({
    sortBy: defaultParams.sortBy,
    sortDirection: defaultParams.sortDirection,
  });

  const onSortChanged = useCallback(
    (column: TableColumn<AdminGetUserDto>, direction: "asc" | "desc") => {
      setSorting({
        sortBy: column.sortField! as AdminGetUsersSortByEnum,
        sortDirection: direction,
      });
    },
    [setSorting]
  );
  const [filter, setFilter] = useState("");

  const users = api.user.useUsers({
    page,
    limit,
    ...sorting,
    accountType,
    filter,
  });
  const { mutateAsync: deleteUserMutation } = api.user.useDeleteUser();

  const { mutateAsync: updateUserMutation } =
    api.user.useUpdateUserVisibility();

  const deleteUser = useCallback(
    async (id: string) => {
      try {
        await deleteUserMutation({
          userId: id,
        });
      } catch (e) {
        snackbarCatch(e, enqueueSnackbar);
      }
    },
    [deleteUserMutation]
  );

  const deleteUserTmp = (rowId) => {
    if (window.confirm("Voulez-vous vraiment supprimer cet utilisateur ?")) {
      deleteUser(rowId);
    }
  };

  const updateUserVisibility = useCallback(
    async (id: string, visible: boolean | null) => {
      try {
        console.log(visible);
        await updateUserMutation({
          userId: id,
          visible,
        });

        setUserData(
          userData?.map((user) => {
            if (user.id === id) {
              return {
                ...user,
                visible,
              };
            }
          })
        );
      } catch (e) {
        console.log(e);
        // snackbarCatch(e, enqueueSnackbar);
      }
    },
    [updateUserMutation]
  );

  const columns = useMemo<TableColumn<AdminGetUserDto>[]>(
    () => [
      {
        name: "Crée le",
        selector: (row) =>
          format(parseISO(row.created_at), "d MMM yyyy", { locale: fr }),
        sortField: "created_at",
        sortable: true,
      },
      {
        name: "email",
        selector: (row) => row.email,
        sortField: "email",
        sortable: true,
        minWidth: "250px",
      },
      {
        name: "username",
        selector: (row) => row.username,
        sortField: "username",
        sortable: true,
      },
      {
        name: "Role",
        selector: (row) => row.role,
        sortField: "role",
        sortable: true,
      },
      {
        name: "Type du compte",
        selector: (row) => row.account_type,
      },
      {
        name: "Nom du restaurant",
        selector: (row) => row.restaurant_name,
        minWidth: "180px",
      },
      {
        name: "visibilité",
        selector: (row) => row.visible,
        // hide: (row) => row.account_type !== "restaurant_owner",
        cell(row, rowIndex, column, id) {
          if (row.account_type !== "restaurant_owner") return <></>;

          let icon;
          let tooltipTitle;
          let newVisibility;

          if (row.visible === true) {
            icon = <RemoveRedEye />;
            tooltipTitle = "Cacher";
            newVisibility = false;
          } else if (row.visible === false) {
            icon = <VisibilityOff />;
            tooltipTitle = "Retrograder";
            newVisibility = null;
          } else if (row.visible === null) {
            icon = (
              <img
                src={halfVis}
                alt="Half Visible"
                style={{ height: "20px", width: "20px" }}
              />
            );
            tooltipTitle = "Faire apparaitre";
            newVisibility = true;
          }

          return (
            <Tooltip title={<Typography>{tooltipTitle}</Typography>}>
              <IconButton
                onClick={() => updateUserVisibility(row.id, newVisibility)}
              >
                {icon}
              </IconButton>
            </Tooltip>
          );
        },
      },
      {
        name: "Nom de famille",
        selector: (row) => row.last_name,
      },
      {
        name: "Action",
        cell: function VoucherAction(row) {
          return (
            <Tooltip title={<Typography>Supprimer</Typography>}>
              <IconButton onClick={() => deleteUserTmp(row.id)}>
                <DeleteRounded />
              </IconButton>
            </Tooltip>
          );
        },
      },
    ],
    [deleteUser]
  );

  const [userData, setUserData] = useState(users.data?.data);
  // useEffect(() => {
  //   setUserData(users.data?.data);
  // }, [users.data?.data]);

  // useEffect(() => {
  //   const tmp = users.data?.data;

  //   if (accountType == "all") {
  //     setUserData(tmp);
  //   } else {
  //     setUserData(tmp?.filter((user) => user.account_type === accountType));
  //   }
  // }, [accountType]);

  const handleChange = (event: SelectChangeEvent) => {
    setAccountType(event.target.value as string);
  };

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };
  useEffect(() => {
    if (filter === "") {
      setUserData(users.data?.data);
    } else {
      setUserData(
        users.data?.data?.filter(
          (user) =>
            user.email?.includes(filter) ||
            user.username?.includes(filter) ||
            user.restaurant_name?.includes(filter)
        )
      );
    }
  }, [filter]);

  if (users.isLoading || users.data === undefined) {
    return (
      <BaseContainer>
        <Typography variant="h3" component="h1">
          Utilisateurs
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <CircularProgress />
        </Box>
      </BaseContainer>
    );
  }

  return (
    <BaseContainer>
      <Typography variant="h3" component="h1">
        Utilisateurs
      </Typography>

      <Box
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <InputLabel
          id="demo-simple-select-label"
          style={{
            marginRight: "10px",
          }}
        >
          Type de compte
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={accountType}
          label="Age"
          onChange={handleChange}
          style={{
            marginRight: "100px",
          }}
        >
          <MenuItem value={"all"}>Tous</MenuItem>
          <MenuItem value={"moover"}>Moover</MenuItem>
          <MenuItem value={"restaurant_owner"}>Restaurateur</MenuItem>
          <MenuItem value={"ambassador"}>Ambassadeur</MenuItem>
          <MenuItem value={"advertiser"}>Annonceur</MenuItem>
        </Select>
        <InputLabel
          id="demo-simple-select-label"
          style={{
            marginRight: "10px",
          }}
        >
          Rechercher un utilisateur
        </InputLabel>
        <TextField
          value={filter}
          onChange={handleFilter}
          label="email ou username"
          style={{
            marginBottom: "18px",
          }}
        />
      </Box>

      <Box mt={3} />

      <DataTable
        persistTableHead
        // noDataComponent
        columns={columns}
        data={users.data?.data}
        responsive
        onSort={onSortChanged}
        sortServer
        noContextMenu
        noHeader
        expandOnRowDoubleClicked
        selectableRowsHighlight
        highlightOnHover
        sortIcon={<ExpandMoreRounded />}
        selectableRowsComponentProps={{ color: "primary" }}
        // onRowClicked
        pagination
        paginationServer
        paginationPerPage={users.data.limit}
        paginationTotalRows={users.data.totalCount}
        paginationRowsPerPageOptions={[5, 10, 20]}
        paginationDefaultPage={defaultParams.page}
        defaultSortAsc={defaultParams.sortDirection === "asc"}
        defaultSortFieldId={defaultParams.sortBy}
        onChangeRowsPerPage={setLimit}
        onChangePage={setPage}
        // fixedHeader
        // fixedHeaderScrollHeight
        keyField="ambassador_id"
      />
    </BaseContainer>
  );
};
export default DashboardScene;
